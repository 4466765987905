import { graphql } from "gatsby"
import React, { FC } from "react"
import { Layout } from "src/components/Layout"
import Jumbotron from "src/components/Jumbotron"
import { Props } from "./types"
import Link from "src/utils/Link"
import * as styles from "./styles.module.scss"
import { usePages } from "src/context/pages"
import { createOptionFromLinkEntry } from "src/components/Header/navigation"
import Logo from "src/images/structured-data-logo.png"

const Template: FC<Props> = ({
  data: {
    file: { childYaml: texts },
  },
  pageContext: { lang },
}) => {
  const { pages, currentPath } = usePages()

  return (
    <Layout
      title={texts.metaTitle}
      description={texts.metaDescription}
      openGraph={{
        title: texts.metaTitle,
        description: texts.metaDescription,
        url: `${process.env.GATSBY_CANONICAL_URL}${currentPath}`,
        images: [
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 800,
            height: 600,
            alt: "Flow logo",
          },
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 900,
            height: 800,
            alt: "Flow logo",
          },
        ],
      }}
      headerClassname={`white-nav`}
    >
      <Jumbotron title={texts.header} subtitle={texts.subheader} />
      <div className={`content-wrapper ${styles.wrapper}`}>
        <div className={styles.grid}>
          {texts.columns.map(column => {
            const links = column.options.map(page => {
              return createOptionFromLinkEntry(page, pages)
            })

            return (
              <div className={styles.column} key={column.name}>
                <h3 className={styles.title}>{column.name}</h3>
                <div className={styles.columnItems}>
                  {links.map(option => (
                    <div className={styles.linkContainer} key={option.id}>
                      <Link
                        lang={lang}
                        to={option.url}
                        target={option.target}
                        key={`column-${column.name}-option-${option.id}`}
                        className={styles.link}
                      >
                        {option.name}
                      </Link>
                    </div>
                  ))}
                  {column.options.length == 2 && (
                    <div className={styles.linkContainer} />
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($ymlPath: String!) {
    file(absolutePath: { eq: $ymlPath }) {
      childYaml {
        metaTitle
        metaDescription
        header
        subheader
        columns {
          name
          options
        }
      }
    }
  }
`

export default Template
